import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { styled } from "@mui/system";

// Styled components
const StyledRefreshIcon = styled(RefreshIcon)(({ theme, loading }) => ({
  color: theme.palette.text.primary,
  animation: loading ? "spin 1s linear infinite" : "none",
  "@keyframes spin": { "100%": { transform: "rotate(360deg)" } },
}));

const MatchDataGrid = ({ endpoint }) => {
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);

  const fetchMatches = useCallback(async (url) => {
    setLoading(true);
    try {
      const { data } = await axios.get(url);
      const rows = [];
      Object.entries(data).forEach(([provider, matches], index) => {
        matches.forEach((match) => {
          const row = {
            id: index,
            provider,
            player1: match.teams[0].name,
            player2: match.teams[1].name,
          };
          match.betOutcomes.forEach((outcome) => {
            const { betType, odds } = outcome;
            row[`${betType}_1`] = odds[0] || "N/A";
            row[`${betType}_2`] = odds[1] || "N/A";
          });
          rows.push(row);
        });
      });
      setMatches(rows);
      updateColumns(rows);
    } catch (error) {
      console.error("Error fetching matches:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchMatches(endpoint);
  }, [endpoint, fetchMatches]);
  
  const updateColumns = (rows) => {
    const columnSet = new Set(["provider", "player1", "player2"]);
    rows.forEach((row) =>
      Object.keys(row).forEach((key) => columnSet.add(key))
    );

    const dynamicColumns = Array.from(columnSet).map((key) => ({
      field: key,
      headerName: key.replace(/_/g, " "),
      width: 150,
      flex: 1,
    }));

    setColumns(dynamicColumns);
  };

  return (
    <div style={{ height: 400, width: "100%" }}>
      <Button
        endIcon={<StyledRefreshIcon loading={loading ? 1 : 0} />}
        onClick={() => fetchMatches(endpoint)}
        disabled={loading}
      >
        Refresh
      </Button>
      {loading ? (
        <DataGrid
          rows={matches}
          columns={columns}
          pageSize={5}
          checkboxSelection
          disableSelectionOnClick
        />
      ) : (
        <DataGrid
          rows={matches}
          columns={columns}
          pageSize={5}
          checkboxSelection
          disableSelectionOnClick
        />
      )}
    </div>
  );
};

export default MatchDataGrid;
