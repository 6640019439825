import React from "react";
import { useState, useEffect } from "react";
import {
  Grid,
  Drawer,
  List,
  ListItem,
  ListItemText,
  CssBaseline,
  Box,
  IconButton,
  SwipeableDrawer,
  AppBar,
  Toolbar,
  Typography,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import MatchDataGrid from "./MatchDataGrid"; // Assuming this is the component provided earlier

const theme = createTheme({
  palette: {
    mode: "dark",
  },
});
const isTouchScreenDevice = () => {
  try {
    document.createEvent("TouchEvent");
    return true;
  } catch (e) {
    return false;
  }
};
const drawerWidth = 240;

const App = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTouchScreen, setIsTouchScreen] = useState(false);
  useEffect(() => {
    setIsTouchScreen(isTouchScreenDevice());
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {isTouchScreen && (
          <Box sx={{ height: "64px" }}>
            <AppBar position="static" style={{ zIndex: 1200 }}>
              <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  Website Title
                </Typography>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={() => setIsOpen(true)}
                >
                  <MenuIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
          </Box>
        )}
        <Box sx={{ flexGrow: 1 }}>
          {isTouchScreen ? (
            <SwipeableDrawer
              anchor="left"
              open={isOpen}
              onOpen={() => setIsOpen(true)}
              onClose={() => setIsOpen(false)}
            >
              <List>
                {["Matches", "History", "Status", "Login"].map(
                  (text, index) => (
                    <ListItem button key={text}>
                      <ListItemText primary={text} />
                    </ListItem>
                  )
                )}
              </List>
            </SwipeableDrawer>
          ) : (
            <Drawer
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: drawerWidth,
                  boxSizing: "border-box",
                },
              }}
              variant="permanent"
              anchor="left"
            >
              <List>
                {["Matches", "History", "Status", "Login"].map(
                  (text, index) => (
                    <ListItem button key={text}>
                      <ListItemText primary={text} />
                    </ListItem>
                  )
                )}
              </List>
            </Drawer>
          )}
          <Box sx={{ flexGrow: 1 }}>
            {isTouchScreen ? (
              <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Grid container spacing={5}>
                  <Grid item xs={12} md={6} lg={12}>
                    <MatchDataGrid
                      endpoint={"https://localhost:7151/tennismatches"}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={12}>
                    <MatchDataGrid
                      endpoint={"https://localhost:7151/tennismatches"}
                    />
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box component="main" sx={{ flexGrow: 1, p: 3, ml: "240px" }}>
                <Grid container spacing={10}>
                  <Grid item xs={12} md={6} lg={12}>
                    <MatchDataGrid
                      endpoint={"https://localhost:7151/tennismatches"}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={12}>
                    <MatchDataGrid
                      endpoint={"https://localhost:7151/tennismatches"}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default App;
